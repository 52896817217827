.App {
  text-align: center;
}

h2{
  font-weight: 500;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  background-color: rgb(204,204,204, 0.3)
}

#logo {
  height: 12rem;
  padding: 0.5rem;
}

#tagline {
  color: #3b5d7e;
  font-size: 3rem;
  padding: 0;
  margin: 0;
  margin-right: 10rem;
}

#navBar {
  margin-right: 4rem;
}

nav {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-top: 1rem;
}

.navItem {
  padding: 1rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  background-color: #cccccc;
  border-radius: 2rem;
  color: #22435e;
}

.navItem:hover {
  padding: 1rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  background-color: #22435e;
  border-radius: 2rem;
  color: #cccccc;
}

a {
  text-decoration: none;
  color: black;
}

p {
  font-size: 1.2rem;
  line-height: 1.3rem;
}

footer {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  padding-left: 5rem;
  padding-right: 5rem;
  background-color: rgb(126,167,204, 0.5)
}

#footerContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.footerText{
  font-weight:500;
}

#emailPhoto {
  height: 3rem;
  margin-right: 4rem
}

#copy{
  font-weight: 400;
}

#flatirons {
  width: 100%;
}

.tablePlace {
  margin-left: 2rem;
  margin-right: 2rem;
}

#tableItem {
  display: flex;
  justify-content: center;
  margin-left: 2rem;
  margin-right: 2rem;
}

#headshot {
  width: 50%;
}

.practiceSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding-left: 10rem;
  padding-right: 10rem;
}

.practiceTitle {
  font-size: 2.3rem;
  color: #24344b;
}

.moreInfoButton {
  width: 16rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  font-size: 1.2rem;
  background-color: #1bae91;
  color: white;
  border-radius: 1rem;
  border-style: solid;

}

.moreInfoButton:hover {
  width: 16rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  font-size: 1.2rem;
  background-color: white;
  color: #1bae91;
  border-radius: 1rem;
  border-style: dashed;
}

#moreInfoButton1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15rem;
  font-size: 1rem;
  background-color: #1bae91;
  color: white;
  border-radius: 1rem;
  border-style: solid;
}

#moreInfoButton1:hover {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15rem;
  font-size: 1rem;
  background-color: white;
  color: #1bae91;
  border-radius: 1rem;
  border-style: dashed;
}

#credentials {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding-left: 10rem;
  padding-right: 10rem;
}

#serviceTable {
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
}

.service {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  text-align: right;
}

.serviceTitle {
  color: #24344b;
  width: 30rem;
  margin-right: 1rem;
  padding-right: 2rem;
}

.serviceInfo {
  color: #24344b;
  width: 30rem;
  text-align: left;
}

#patients {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.forms {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  font-size: 1.5rem;
  color: #1bae91;
  background-color: #1bae91;
  border-radius: 1rem;
  margin: 2rem;
  border-style: solid;
}

.forms:hover {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  font-size: 1.5rem;
  color: #1bae91;
  background-color: white;
  border-radius: 1rem;
  border-style: dashed;
  margin: 2rem;
}

.formButton {
  color: white;
}

.formButton:hover {
  color: #1bae91;
}

#infoBoxes {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.infoBox{
  width: 28%;
  background-color: #5282b0;
  color: white;
  margin:1rem
}

.infoBox2{
  width: 28%
}

.moreInfoLinks {
  color: white;
}

.moreInfoLinks:hover {
  color: #1bae91;
}

#videos{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.moreInfoVideo{
  margin: 0.5rem
}

#contactForm {
  font-size: 2rem;
  display:flex;
  align-items: center;
  justify-content: center;
}

.formInput{
  width:100%;
  height:2rem;
  margin-right: 1rem
}

#message {
  width: 100%;
  height: 10rem;
}

#submitButton {
  height: 40px;
  font-size: 1.5rem;
  background-color: #1bae91;
  color: white;
  border-radius: 4px;
}

#submitButton:hover {
  height: 40px;
  font-size: 1.5rem;
  background-color: white;
  color: #1bae91;
  border-radius: 4px;
}
